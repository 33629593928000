@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.navBarItem {
    @apply font-medium text-white/[.9] hover:text-white sm:py-6;
}

.navBarDropdownItem {
    @apply flex items-center gap-x-3.5 py-2 px-2 rounded-md text-sm text-gray-800 hover:bg-primary hover:text-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300;
}

.searchCategoryButton {
    @apply m-1 py-1.5 px-3 inline-flex justify-center items-center gap-1.5 font-medium text-gray-500 rounded-md border bg-white shadow-sm align-middle hover:bg-gray-50 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white ;
} 

.searchCategoryFocused {
    @apply text-gray-800 outline-none ring-2 ring-offset-1 ring-offset-primary-light font-bold ring-primary-light dark:focus:ring-offset-gray-800;
}



.footerSocialLink {
    @apply inline-flex justify-center items-center w-9 h-9 text-center text-gray-100 hover:bg-[#33333375] rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition dark:text-gray-500 dark:hover:text-gray-200 dark:hover:bg-gray-800 dark:focus:ring-offset-slate-900;
}

@layer components {
    .customButton {
        @apply flex items-center justify-center gap-x-2 rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium text-white duration-150 ease-in-out hover:bg-primary-dark;
    };

    .btnDanger {
        @apply bg-red-700 hover:bg-red-900;
    };

    .btnWarning {
        @apply bg-yellow-700 hover:bg-yellow-900;
    }
    .customInput {
        @apply flex w-full border border-gray-200 rounded-md text-sm px-3 py-2.5  dark:border-gray-700 dark:text-gray-400;
    };

    .dropdownOption {
        @apply absolute mt-2 px-1.5 py-1 bg-white border shadow-sm rounded-lg overflow-hidden w-full;
    };

    .chipSelectItem {
        @apply m-1 py-1.5 px-3 inline-flex justify-center items-center gap-1.5 font-medium text-gray-500 rounded-md border bg-white shadow-sm align-middle hover:bg-gray-50 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white ;
    } 
    
    .chipSelectItemFocused {
        @apply text-gray-800 outline-none ring-2 ring-offset-1 ring-offset-primary ring-primary dark:focus:ring-offset-gray-800;
    }
}